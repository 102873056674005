import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, Strong, Box, Section } from "@quarkly/widgets";
import { IoMdLocate, IoIosContact } from "react-icons/io";
const defaultProps = {
	"padding": "90px 0 250px 0",
	"sm-padding": "40px 0",
	"min-height": "600px",
	"sm-min-height": "auto",
	"display": "flex",
	"lg-padding": "45px 0 45px 0",
	"quarkly-title": "Advantages/Features-18"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "100%",
			"flex-direction": "column",
			"align-items": "center",
			"lg-width": "100%",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "center",
			"sm-justify-content": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "4px 0px 12px 0px",
			"font": "--headline2",
			"color": "--darkL2",
			"width": "100%",
			"md-width": "100%",
			"text-align": "center",
			"sm-text-align": "left",
			"children": "İletişime Geçin"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "8px 0px 0px 0px",
			"font": "--lead",
			"text-align": "center",
			"color": "--darkL2",
			"width": "70%",
			"lg-width": "100%",
			"sm-text-align": "left",
			"children": "Bella Gusto farkını yaşamaya hazır mısınız? Bizi ziyaret edin veya iletişime geçin - size şehirdeki en iyi pizzayı sunmak için buradayız."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "24px",
			"margin": "60px 0px 0px 0px",
			"max-width": "1000px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "6px",
			"sm-grid-gap": "32px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "24px 24px 48px 24px",
			"border-radius": "8px",
			"display": "flex",
			"sm-padding": "0px 0px 0px 0px",
			"md-padding": "24px 24px 24px 24px",
			"sm-flex-direction": "column"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoMdLocate,
			"size": "42px",
			"color": "--light",
			"margin": "0px 24px 0px 0px",
			"background": "--color-primary",
			"padding": "8px 8px 8px 8px",
			"border-radius": "8px",
			"sm-margin": "0px 0px 12px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--darkL2",
			"margin": "0px 0px 0px 0px",
			"letter-spacing": "0.3px",
			"sm-margin": "4px 0px 0px 0px",
			"children": <Strong>
				Bizi ziyaret et
			</Strong>
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "16px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL1",
			"sm-margin": "6px 0px 0px 0px",
			"children": "Gençlik, 1319. Sk. No:4 D:1, 07100 Muratpaşa/Antalya, Türkiye"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "24px 24px 48px 24px",
			"border-radius": "8px",
			"display": "flex",
			"sm-padding": "0px 0px 0px 0px",
			"md-padding": "24px 24px 24px 24px",
			"sm-flex-direction": "column"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosContact,
			"size": "42px",
			"color": "--light",
			"margin": "0px 24px 0px 0px",
			"background": "--color-primary",
			"padding": "8px 8px 8px 8px",
			"border-radius": "8px",
			"sm-margin": "0px 0px 12px 0px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--darkL2",
			"margin": "0px 0px 0px 0px",
			"letter-spacing": "0.3px",
			"sm-margin": "4px 0px 0px 0px",
			"children": <Strong>
				Bize Ulaşın
			</Strong>
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "25px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL1",
			"sm-margin": "6px 0px 0px 0px",
			"children": "+90 242 244 9090"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "20px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL1",
			"sm-margin": "6px 0px 0px 0px",
			"children": "contact@nexifygang.com"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			flex-wrap="wrap"
			grid-gap="5%"
			max-width="1200px"
			sm-min-width="280px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Icon {...override("icon")} />
					<Box {...override("box3")}>
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
				</Box>
				<Box {...override("box4")}>
					<Icon {...override("icon1")} />
					<Box {...override("box5")}>
						<Text {...override("text4")} />
						<Text {...override("text5")} />
						<Text {...override("text6")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;